import React, { Component } from "react";

class InputCourse extends Component {
    state = {
        name: ""
    };

    onChange = e => {
        this.setState({
            name: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        console.log("moo", this.props)
        this.props.addCourseProps(this.state.name);
        this.setState({
            name: ""
        });
    };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="f-container">
        <label for="task">Course Add:</label>
        <input type="text" id="task" placeholder="Add course..." 
        value={this.state.name} onChange={this.onChange} className="f-input" />
        <input type="submit" value="SUBMIT" className="f-submit" />
      </form>
    )
  }
}
export default InputCourse