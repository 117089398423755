import React from "react";

class CourseItem extends React.Component {

    handleOnClick() {

    }
    render () {
        const completeStyle = {
            fontStyle: "italic",
            color: "#d35e0f",
            opacity: 0.5,
            textDecoration: "line-through"
        }

        return <table className="course-item">
            <tr><td width="10%">
        <button onClick={() => 
                this.props.deleteCourseProps(this.props.course.id)}
                    disabled={!this.props.course.delete_ok} >Delete </button>
                    </td>

            <td>{this.props.course.name}</td></tr>
        </table>
    }
}

export default CourseItem