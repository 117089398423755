import React from "react";
import CourseList from "./CourseList";
import Header from "./Header";
import InputCourse from "./InputCourse";
import { v4 as uuidv4 } from "uuid";

class CourseContainer extends React.Component {
    state = {
        courses: [
          {
            id: uuidv4(),
            name: "CNIT 103 - Hardware – 3 units",
            delete_ok: true
          },
          {
            id: uuidv4(),
            name: "CNIT 131 – Internet & Intro to HTML, CSS – 3 units",
            delete_ok: true
          },
          {
            id: uuidv4(),
            name: "CNIT 106 – Introduction to Networks – 3 units",
            delete_ok: true
          },
          {
            id: uuidv4(),
            name: "CNIT 120 – Network Security – 3 units",
            delete_ok: true
          }
        ]
      };

      handleChange = (id) => {
        this.setState({
            courses: this.state.courses.map(course => {
                if (course.id === id) {
                    course.completed = !course.completed;
                }
                return course;
            })
        });
    };

  deleteCourse = id => {
      this.setState({
          courses: [
              ...this.state.courses.filter(course => {
                if ( course.delete_ok === false || course.id !== id ) {
                  return course.id
                }
              })
          ]
      });
  };  

  addCourseItem = name => {
      const newCourse = {
          id: uuidv4(),
          name: name,
          delete_ok: false
      };
      this.setState({
          courses: [...this.state.courses, newCourse]
      });
  };

  render() {
    return (
      <div className="container">
          <Header />
          <InputCourse addCourseProps={this.addCourseItem} />
          <CourseList courses={this.state.courses} 
          handleChangeProps={this.handleChange}
          deleteCourseProps={this.deleteCourse} />
      </div>
    )
  }
}
export default CourseContainer