import React from "react";

const Header = () => {
  return (
    <header>
      <h1>Course App</h1>
      <p>Add Courses you want to take</p>
    </header>
  )
}

export default Header